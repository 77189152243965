import React, { Component } from 'react'
import axios from 'axios'

class NewsDetail extends Component {
  constructor (props) {
    super(props)

    this.state = {
      newsDetail: {},
      notFound: true
    }

    const { route } = this.props

    this.newsId = route.match.params.id
  }

  componentWillMount () {
    this.getNewsDetail()
  }

  getNewsDetail () {
    axios.get('https://service.todab.org.tr/get-news-detail/' + this.newsId)
      .then(
        response => {
          if (response.data.status) {
            this.setState({ newsDetail: response.data.data, notFound: false })
          } else {
            this.setState({ notFound: true })
          }
        },
        error => console.log('error', error)
      )
  }

  renderNews () {
    if (typeof this.state.newsDetail.subject !== 'undefined') {
      const { newsDetail } = this.state;
      return (
        <>
          <div className={'row'}>
            <div className={'col-lg-12'}>
              <h1 align="center"><b> { newsDetail.subject } </b></h1>
              <hr/>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-lg-4'}>
              <img src={`https://service.todab.org.tr/newsImages/${newsDetail.main_image}`}
                   style={{ maxWidth: '100%', width: 'auto', height: 250 }}
              />
            </div>
            <div className={'col-lg-8'} dangerouslySetInnerHTML={{__html: newsDetail.content}}>
            </div>
          </div>
        </>)
    } else {
      return (<h4 align='center'>Lütfen Bekleyiniz</h4>)
    }
  }

  render () {
    return (
      <div className={'container'}>
        { this.renderNews() }
      </div>
    )
  }
}

export default NewsDetail
