/* eslint-disable */
import React, { Component } from 'react';
import Map from './../Map/index';
import { connect } from 'react-redux'
import { fetchPosts, fetchSearchUtilities, setSearchKeyword } from './../../redux/actions/mainActions';
import { PropagateLoader } from 'react-spinners';
import Banner from './../Banner/index';
import SearchResults from '../SearchResults';
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import axios from 'axios'
/*import {boot} from '../Survey/surveyHelper';*/
/*import Survey from '../Survey/Survey'*/

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


class Home extends Component {


  constructor(props ) {
    super(props);

    this.typeSelects = {
      'types' : 'tur',
      'categories': 'kategori',
      'kinds': 'cesit',
      'cities': 'il',
      'counties': 'ilce'
    };

    this.state = {
      kelime: '',
      il: null,
      ilce: null,
      cesit: null,
      tur: null,
      kategori: null,

      tipId: null,
      keywordId: null,
    };

    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
  }


  search(id='', type='', forwarder = '') {
    this.props.onSearch({kelime: this.props.searchUtilies.searchKeyword, id:id, tip:type});
    const old = window.location.origin;

    window.gtag_report_conversion({
      type: 'search',
      forwarder: (forwarder !== '') ? forwarder : 'unknown',
      searchKeyword: this.props.searchUtilies.searchKeyword
    });

    if (forwarder === 'advanced_search') {
      window.location.href = `${old}#searchArea`
    } else {
      if (isMobile) {
        window.location.href = `${old}#resultsTable`
      } else {
        window.location.href = `${old}#searchArea`
      }
    }



  };


  searchQuick(inputValue) {
    return axios.get(`https://service.todab.org.tr/quick-search?query=${inputValue}`)
                .then(
                  response => response.data.data,
                  error => null
                );
  }

  loadOptions(inputValue){
    return new Promise(resolve => {
      resolve(this.searchQuick(inputValue).then(data => {return data}));
    });
  }

  searchAction = (pageNumber = 1, ek_kelime = null) => {

    window.gtag_report_conversion({
      type: 'search',
      forwarder: 'detailed_search',
      searchKeyword: this.state.kelime || ''
    });

    let params = {...this.state};
    let {tipId, keywordId} = this.state;
    delete params.tipId;
    delete params.keywordId;
    params.id = keywordId;
    params.tip = tipId;
    params.start = ((pageNumber - 1) * 25);

    if (ek_kelime !== null ) {
      params.ek_kelime = ek_kelime;
    }
    this.props.onSearch(params);
  }


  handleInputOnChange = (e) => {
    this.tmp_name = e.target.name;

    if (e.target.name === 'kelime') {
      this.props.setSearchKeyword(e.target.value);
    }
    this.setState({[e.target.name]: e.target.value}, () => {
      switch (this.tmp_name) {
        case "kategori":
          this.props.getUtilities('types', this.state.kategori);
          break;
        case "tur":
          this.props.getUtilities('kinds', this.state.tur);
          break;
        case 'il':
          return this.props.getUtilities('counties', this.state.il);
        default:
          return null;
          break;
      }

    });
  };

  componentDidMount() {
    this.props.getUtilities('categories');
    this.props.getUtilities('cities');

    // anket
    // const tk = boot();
  }


  renderDynamicTypeSelects(selectType) {
    let options = [];

    (Object.keys(this.props.searchUtilies[selectType] || []).map((key) => {
      options.push(<option key={`${selectType}-keyid-${key}`} value={key}>{this.props.searchUtilies[selectType][key]}</option>);
    }));

    return (<select name={this.typeSelects[selectType]} onChange={this.handleInputOnChange} value={this.state[this.typeSelects[selectType]]}>
      <option selected value={-1}>Lütfen Seçiniz.</option>
      {options}
    </select>);
  }

  render() {

    return (
      <div>
        {/*<Survey/>*/}
        <div className={'container fluid'} style={{marginBottom:30}}>
          <div className={'row'}>
            <div className={'col-lg-2'}></div>
            <div className={'col-lg-6'}>
              <AsyncSelect
                cacheOptions defaultOptions
                loadOptions={this.loadOptions}
                placeholder={'Aramak İstediğiniz Tohumluğu Yazınız'}
                onChange={(val) => {
                  this.props.setSearchKeyword(val.value);
                  setTimeout(() => {
                    this.search(val.id, val.tip, 'header_quick_search');
                  }, 200);
                }}
              />

            </div>
            <div className={'col-lg-2'}>
              <button className={'btn btn-warning btn-lg'}  style={{backgroundColor:'orange', width:'100%'}} onClick={() => this.search('', '', 'advanced_search')}>Detaylı Ara</button>
            </div>
            <div className={'col-lg-2'}></div>
          </div>

          <div className={'row'}>
            <div className={'col-lg-12'}>

            </div>
          </div>
        </div>

        <Banner />

        <div className={"environment-main-content"} style={{paddingTop:0, marginTop:'0px'}}>

          <div>
            <div className={"environment-main-content hidden-jumbotron"}>

              <div className={"environment-main-section environment-minus-margin"} style={{ height: 152 }}>
                <div className={""}>
                  <div className={"row"}>

                    <div className={"col-md-12"}>
                      <div className={"enviroment-services-strip"} style={{ padding: '36px 0px 0px 0px'}}>
                        <ul className={"row"}>
                          <li className={"col-md-12"}>
                            <h2><span>Tohum Nerede ?</span></h2>
                            <h4 style={{paddingLeft:90}}><span>Aşağıdaki <b>Arama Formu</b>"nu Kullanarak Sonuçları Listeleyebilirsiniz.</span></h4>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"environment-main-section environment-services-space"} style={{padding:'0px 50px'}}>
            <div className={"container"} >

              {
                this.props.searchUtilies.error === true &&
                <div className={"row"}>
                  <div className={"col-lg-12"}>
                    <h4 style={{color:'crimson'}}>Bir Hata Meydana Geldi, Lütfen Daha Sonra Tekrar Deneyiniz.</h4>
                  </div>
                </div>

              }

              <div className={"row"} id={'searchArea'}>
                <div className={"col-lg-3"}>
                  <div className={"form-group"}>
                    <label>Kategori</label>
                    {this.renderDynamicTypeSelects('categories')}
                  </div>
                  <div className={"form-group"}>
                    <label>Tür</label>
                    {this.renderDynamicTypeSelects('types')}
                  </div>
                  <div className={"form-group"}>
                    <label>Çeşit</label>
                    {this.renderDynamicTypeSelects('kinds')}
                  </div>

                  <div className={"form-group"}>
                    <label>İl</label>
                    {this.renderDynamicTypeSelects('cities')}
                  </div>
                  <div className={"form-group"}>
                    <label>İlçe</label>
                    {this.renderDynamicTypeSelects('counties')}
                  </div>

                  <div className={"form-group"}>
                    <label>Kelime İle Ara</label>

                    <AsyncSelect
                      cacheOptions defaultOptions
                      loadOptions={this.loadOptions}
                      placeholder={'Aramak İstediğiniz Tohumluğu Yazınız'}
                      onChange={(val) => {
                        this.props.setSearchKeyword(val.value);
                        this.setState({kelime: val.value, tipId: val.tip, keywordId: val.id}, () => {
                          this.searchAction();
                        });

                      }}
                    />

                  </div>

                  <button className={ "btn btn-success btn-lg" } style={ {width: "100%"} }
                          onClick={ () => {
                            window.gtag_report_conversion({
                              type: 'search',
                              forwarder: 'advanced_search_button'
                            });
                            this.props.onSearch(this.state)
                          } }>
                    <span style={ {fontSize: 30, color: "white"} }><i className="fa fa-search"></i> Tohumluk Ara</span>
                  </button>

                </div>

                <div className="col-lg-9">
                  <Map />

                  <SearchResults changePage={this.searchAction} />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}


const mapStateToProps = state => {
  return {
    results: state.postsByAPI.results,
    didInvalidate: state.postsByAPI.didInvalidate,
    error: state.postsByAPI.error,
    isFetching: state.postsByAPI.isFetching,
    searchUtilies: state.utilitiesAPI
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSearch: (params) => {
      dispatch(fetchPosts(params))
    },
    getUtilities: (type, optionsId = null) => {
      dispatch(fetchSearchUtilities(type, optionsId));
    },
    setSearchKeyword: (keyword) => {
      dispatch(setSearchKeyword(keyword));
    }

  }
}


Home = connect(mapStateToProps, mapDispatchToProps)(Home);


export default Home;
