import React, { Component } from 'react'

import bannerOne from './../../assets/extra-images/banner-1.jpg'
import axios from 'axios';
import Slider from "react-slick";
import bannerStyle from './banner.css'

const settings = {
  autoplay: true,
  autoplaySpeed: 3000,
  infinite: true,
  dots: true,
  arrows: true,
  fade: false,
  delay: 1000,
  customPaging: i => (
    <button>{i+1}</button>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
settings.onSwipe = function() {
  settings.fade = true;
}


class Banner extends Component {
  constructor (props) {
    super(props);
    this.state = {
      sliders: []
    }
  }



  fetchSliders () {
    axios.get('https://service.todab.org.tr/frontend-get-sliders')
      .then(response => {
          let {data} = response.data;
          this.setState({sliders: data});
      });

  }

  componentDidMount () {
    this.fetchSliders();
  }


  renderSlides () {
    let slides = [];

    let index = 0;
    this.state.sliders.map(slider => {
      if (slider.type === 'image') {
        slides.push(this.renderImageSlider(slider, index));
      } else {
        slides.push(this.renderVideoSlider(slider, index));
      }
      index+=1;
    });

    return (<Slider {...settings}>
              {slides}
            </Slider>)
  }


  renderImageSlider(data, index) {
    return (
        <div className={'environment-banner-one-layer'}>
          <img src={bannerOne} alt='' style={{width:'100%', height:438}} />
          <div className={'environment-banner-caption'} style={{ height: '100%'}}>
            <div className={'container'} style={{ width: '100%', paddingLeft:'40px' }}>
              <div className={'banner-caption-text'} style={{
                position: 'absolute',
                zIndex: 900,
                marginTop: '0px'
              }}>
                <h1 className={bannerStyle.bannerCustomH1} style={{ fontSize: 30, marginBottom:0 }} >{data.header}</h1>

                <div style={{color:'white'}} dangerouslySetInnerHTML={{__html: data.content}}></div>

                {
                  data.button_enabled === 1 ?
                    <a href={data.button_url} className={'environment-bgcolor'} style={{backgroundColor:'white', color:'#1da34c', marginTop:'25px'}}>{data.button_text}</a>
                    : <></>
                }

              </div>
              <div className={'banner-thumb'} style={{ overflow: 'auto', alignItems: 'center', width: '50%', justifyContent: 'center', alignContent: 'center', marginTop: '50px' }} >
                <img src={data.source_url} />
              </div>
            </div>
          </div>
    </div>
      )
  }


  renderVideoSlider(data, index) {

    let element = (<video autoPlay loop style={{ display: 'block', margin: '0px auto', width: '77%', height:'380px' }}>
      <source src={data.source_url} />
    </video>);

    if (data.source_url.indexOf('youtube') !== -1) {
      element = (<iframe className={"ytb_video"} width="600" height="380" src={data.source_url} frameBorder="0"
                         allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                         allowFullScreen></iframe>);
    }

    return (
        <div className={'environment-banner-one-layer'}>
          <img src={bannerOne} alt='' style={{width:'100%', height:440}} />
          <div className={'environment-banner-caption'} style={{ height: '100%'}}>
            <div className={'container'} style={{ width: '100%', paddingLeft:'40px' }}>
              <div className={'banner-caption-text'} style={{
                position: 'absolute',
                zIndex: 900,
                marginTop: '0px'
              }}>
                <h1 className={bannerStyle.bannerCustomH1} style={{ fontSize: 25 }} > {data.header}</h1>

                <div style={{color:'white'}} dangerouslySetInnerHTML={{__html: data.content}}></div>

                {
                  data.button_enabled === 1 ?
                    <a href={data.button_url} className={'environment-bgcolor'} style={{backgroundColor:'white', color:'#1da34c', marginTop:'25px'}}>{data.button_text}</a>
                    : <></>
                }

              </div>
              <div className={'banner-thumb video'} style={{ overflow: 'auto', alignItems: 'center', width: '50%', justifyContent: 'center', alignContent: 'center', marginTop: '50px' }} >
                {element}
              </div>
            </div>
          </div>
        </div>
    )
  }


  componentWillMount () {

  }

  render (){

    let index = 0;
    return (
      <Slider {...settings}>
        {this.state.sliders.map(slide => {
          if (slide.type === 'image') {
            return (this.renderImageSlider(slide, index))
          } else {
            return (this.renderVideoSlider(slide, index))
          }
          index+=1;
        }) }
      </Slider>
    )
  }
}

//

export default Banner
