/* eslint-disable */
/* eslint no-undef: "off" */
/*eslint no-unused-expressions: "off"*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from './../../components/Header/header';
import Banner from '../../components/Banner';
import Footer from '../../components/Footer';
import { PropagateLoader } from 'react-spinners';
import { connect } from 'react-redux'
import { fetchPosts, setSearchKeyword } from '../../redux/actions/mainActions'
import AsyncSelect from 'react-select/async';
import axios from 'axios';


class PublicLayout extends Component {

  constructor (props) {
    super(props);
  }


    componentDidMount() {
      axios.post('https://service.todab.org.tr/hit');
    }

    render() {
        const Component = this.props.component;
        const route = this.props.route;
        return (
            <div>
                <div id="loader" style={{
                    position: 'fixed',
                    zIndex: 9999,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    height: 10,
                    width: 10,
                    right: 0,
                    margin: 'auto'
                }}>
                    <PropagateLoader
                        sizeUnit={"px"}
                        size={15}
                        color={'#123abc'}
                        loading={this.props.isFetching || this.props.isFetchingContact || this.props.isFetchingContactSending}
                    />
                </div>

                <div className={"environment-main-wrapper"} style={{
                    opacity: (this.props.isFetching || this.props.isFetchingContact || this.props.isFetchingContactSending) ? 0.3 : 1
                }}>
                  <div style={{width:'100%', height:'30px'}}></div>
                    <Header route={this.props.route}/>



                    <Component route={route}/>


                    <Footer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.postsByAPI.isFetching,
        isFetchingContact: state.contactAPI.isFetching,
        isFetchingContactSending: state.contactSendAPI.isFetching,
        searchUtilies: state.utilitiesAPI

    }
}

const mapDispatchToProps = dispatch => {
  return {
    setSearchKeyword: keyword => {
      dispatch(setSearchKeyword(keyword));
    },
    onSearch: (params) => {
      dispatch(fetchPosts(params))
    },
  }
}

PublicLayout = connect(mapStateToProps, mapDispatchToProps)(PublicLayout);

export default PublicLayout;
