import fetch from 'cross-fetch'
import jQuery from 'jquery'
export const START_SEARCH = 'START_SEARCH'
export const END_SEARCH = 'END_SEARCH'
export const INVALID_SEARCH = 'INVALID_SEARCH'
export const ERROR_SEARCH = 'ERROR_SEARCH'

export const START_GET_UTILITIES = 'START_GET_UTILITIES'
export const END_GET_UTILITIES = 'END_GET_UTILITIES'
export const ERROR_GET_UTILITIES = 'ERROR_GET_UTILITIES'
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD'

/** Contact Data Icin */
export const START_GET_CONTACT_DATA = 'START_GET_CONTACT_DATA'
export const END_GET_CONTACT_DATA = 'END_GET_CONTACT_DATA'
export const ERROR_GET_CONTACT_DATA = 'ERROR_GET_CONTACT_DATA'

/** Contact SEND API Icin */
export const START_SEND_CONTACT_DATA = 'START_SEND_CONTACT_DATA'
export const END_SEND_CONTACT_DATA = 'END_SEND_CONTACT_DATA'
export const ERROR_SEND_CONTACT_DATA = 'ERROR_SEND_CONTACT_DATA'

export const API_BASE_URI = 'https://portal.todab.org.tr/t-api'
export function startSearch (queries) {
  return {
    type: START_SEARCH,
    payload: queries
  }
}

function endSearch (queries, json) {
  return {
    type: END_SEARCH,
    json,
    results: json.data,
    receivedAt: Date.now()
  }
}

function errorSearch () {
  return {
    type: ERROR_SEARCH,
    isError: false,
    results: [],
    data: []
  }
}

export function fetchPosts (queries) {
  return function (dispatch) {
    dispatch(startSearch(queries))

    let queryString = jQuery.param(queries)
    return fetch(`${API_BASE_URI}/get-search-results?${queryString}`)
      .then(
        response => response.json(),
        // Do not use catch, because that will also catch
        // any errors in the dispatch and resulting render,
        // causing a loop of 'Unexpected batch number' errors.
        // https://github.com/facebook/react/issues/6895
        error => dispatch(errorSearch())
      )
      .then(json => {
        // We can dispatch many times!
        // Here, we update the app state with the results of the API call.
        dispatch(endSearch(queries, json))
      })
  }
}

export function startGetUtilities () {
  return {
    type: START_GET_UTILITIES,
    payload: null
  }
}

function endGetUtilities (type, json) {
  return {
    type: END_GET_UTILITIES,
    json,
    processType: type,
    [type]: json.data,
    receivedAt: Date.now()
  }
}

function errorGetUtilities () {
  return {
    type: ERROR_GET_UTILITIES,
    isError: true
  }
}

export function setSearchKeyword(keyword) {
  return {
    type: SET_SEARCH_KEYWORD,
    payload: { keyword }
  }
}

export const searchUtilityTypes = {
  'categories': 'get-kategori-options',
  'types': 'get-tur-options',
  'kinds': 'get-cesit-options',
  'cities': 'get-il-options',
  'counties': 'get-ilce-options'
}

export function fetchSearchUtilities (type, optionsId = null) {
  return function (dispatch) {
    dispatch(startGetUtilities())
    const searchType = searchUtilityTypes[type]
    let requestUrl = `${API_BASE_URI}/${searchType}`
    if (type !== 'categories' && type !== 'cities') {
      requestUrl += `/${optionsId}`
    }
    return fetch(requestUrl)
      .then(
        response => response.json(),
        error => dispatch(errorGetUtilities())
      )
      .then(json => {
        if (typeof json.isError === 'undefined' || json.isError === false) {
          dispatch(endGetUtilities(type, json))
        }
      })
  }
}

/** Contact API Icin */

export function startGetContactData () {
  return {
    type: START_GET_CONTACT_DATA,
    payload: null
  }
}

function endGetContactData (json) {
  return {
    type: END_GET_CONTACT_DATA,
    json,
    contactData: typeof json.data !== 'undefined' ? json.data : {},
    notFound: !json.status ? true : undefined,
    receivedAt: Date.now()
  }
}

function errorGetContactData () {
  return {
    type: ERROR_GET_CONTACT_DATA,
    isError: true
  }
}

export function fetchContactData (stockId = null) {
  return function (dispatch) {
    dispatch(startGetContactData())
    let requestUrl = `${API_BASE_URI}/get-stock-contact/${stockId}`
    return fetch(requestUrl)
      .then(
        response => response.json(),
        error => dispatch(errorGetContactData())
      )
      .then(json => {
        if (typeof json.isError === 'undefined' || json.isError === false) {
          dispatch(endGetContactData(json))
        }
      })
  }
}

/** Contact form send */
export function startSendContactData () {
  return {
    type: START_SEND_CONTACT_DATA,
    payload: null
  }
}

function endSendContactData (json) {
  return {
    type: END_SEND_CONTACT_DATA,
    json,
    status: json.status
  }
}

function errorSendContactData () {
  return {
    type: ERROR_SEND_CONTACT_DATA,
    sendError: true
  }
}
export function sendContactData (stockId, formData = null, captchaResponse = null) {
  return function (dispatch) {
    dispatch(startSendContactData())
    let requestUrl = `${API_BASE_URI}/save-stock-contact/${stockId}`
    if (stockId === null) {
      requestUrl = `${API_BASE_URI}/save-contact`
    }

    formData['recaptcha-response'] = captchaResponse;
    return fetch(requestUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(
        response => response.json(),
        error => dispatch(errorSendContactData())
      )
      .then(json => {
        if (typeof json.sendError === 'undefined' || json.sendError === false) {
          dispatch(endSendContactData(json))
        }
      })
  }
}
