import React, { Component } from 'react'
import axios from 'axios';
import _ from 'lodash';

class Announcements extends Component {

  constructor (props) {
    super(props);
    this.state = {
      news: []
    }
  }

  componentWillMount () {
    this.getNews()
  }

  getNews () {
    axios.get('https://service.todab.org.tr/get-news', { params: { type: 'announcement' } })
      .then(
        response => this.setState({ news: response.data.data }),
        error => console.log('fetch error:', error)
      )
  }

  renderNew (details) {
    let newContent = details.content.replace(/<[^>]*>?/gm, '');
    newContent = newContent.substring(0, 50);
    return (
      <li className="col-md-3">
        <figure><a href={`/duyurular/detay/${details.id}`}>
          <img
            style={{ maxWidth: '100%', width: 'auto', height: 250 }}
            src={`https://service.todab.org.tr/newsImages/${details.main_image}`} alt='' />
          <i className='fa fa-share-square-o'></i></a></figure>
        <div className='environment-blog-grid-text'>
          <h4><a href={`/duyurular/detay/${details.id}`}>{details.subject}</a></h4>
          <p> {newContent} </p>
          <ul className='blog-grid-option'>
            <li>
              <i className='fa fa-calendar-o'></i>
              <time dateTime={details.created_at}>{details.created_at}</time>
            </li>
            <li>
              <i className='fa fa-user'></i>
              <a>Tohum Nerede</a>
            </li>
          </ul>
        </div>
      </li>
    );
  }

  render() {

    return (
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <h1 align="center"> Duyurular </h1>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-lg-12'}>

            <div className={'environment-blog environment-blog-grid'} style={{ marginBottom: 30 }}>
                <ul className={'row'}>
                  {
                    _.map(this.state.news, (news) => {
                      return (<>{this.renderNew(news)}</>)
                    })
                  }
                </ul>

              {
                this.state.news.length === 0 &&
                  <h3 align="center">Duyuru Bulunamadı</h3>
              }
            </div>

          </div>
        </div>
      </div>
    )
  }

}

export default Announcements;