import Home from './../components/Home/home';
import Contact from './../components/Contact';
import News from './../components/News/news';
import NewsDetail from '../components/News/newsDetail'
import AnnouncementDetail from '../components/Announcements/announcementDetail'
import Announcements from '../components/Announcements/announcements'
import AboutUs from '../components/AboutUs/index'
import ContactUs from '../components/ContactUs'

export default {
    Home: {
        component: Home,
        path: '/'
    },
    HomeIndex: {
        component: Home,
        path: '/index.html'
    },
    ContactUs: {
        component: ContactUs,
        path: '/contact-us'
    },

    Contact: {
        component: Contact,
        path: '/iletisim/:id'
    },

    News: {
        component: News,
        path: '/haberler'
    },

    NewsDetail: {
        component: NewsDetail,
        path: '/haberler/detay/:id'
    },

    Announcements: {
        component: Announcements,
        path: '/duyurular'
    },

    AnnouncementDetail: {
        component: AnnouncementDetail,
        path: '/duyurular/detay/:id'
    },

    AboutUs: {
        component: AboutUs,
        path: '/hakkimizda'
    }
};