import React from 'react';
import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'

import { fetchPosts } from './redux/actions/mainActions';

function App(props) {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>


        {props.isFetching && <h1>Yükleniyor</h1> }
        <button onClick={props.onClick}>Tıkla</button>

        

        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    results: state.postsByAPI.results,
    didInvalidate: state.postsByAPI.didInvalidate,
    isFetching: state.postsByAPI.isFetching
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: () => {
      dispatch(fetchPosts({text: "meme"}))
    }
  }
}

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
