import React, { Component } from 'react'

import logo  from "./../../assets/img/logo.png";
import weather from "./../../assets/images/weather.png";
import headerStyles from "./header.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class Header extends Component {


  constructor (props) {
    super(props);
    this.state = {
      weather: 29,
      marquee_text: []
    }

    this.checkActiveClassHeader = this.checkActiveClassHeader.bind(this);
  }

  componentWillMount() {
    fetch('https://service.todab.org.tr/get-weather')
      .then(res => res.json())
      .then(response => {
        let data = response;
        if (typeof data.data.id !=='undefined') {
          this.setState({weather: data.data.value});
        }
      });

    fetch('https://service.todab.org.tr/get-marquee-text')
      .then(res => res.json())
      .then(response => {
        let data = response;
        if (typeof data.data.marquee_text !=='undefined') {
          this.setState({marquee_text: data.data.marquee_text});
        }
      });
  }

  checkActiveClassHeader (path, indexOf = true) {
    const pathname = this.props.route.location.pathname;
    if (!indexOf) {
        return path === pathname ? 'active' : '';
    }

    if (pathname.indexOf(path) !== -1) {
      //console.log('PATHMAME', pathname, 'PATH', path);
      return 'active';
    }

    return '';
  }

  render() {
    return (
      <header id="environment-header" className={"environment-header-one"}>
        <div className={"container fluid"}>
          <div className={`row headerContainerSubDiv`} >
            <div className={"col-md-12"}>

              <div className={'row'}>
                <div className={'col-lg-4 col-md-12'}>
                  <img onClick={() => window.location.href = '/'}
                       src={logo}
                       style={{ height:122, marginTop:-3, position:'relative', float:'left', backgroundColor:'white',
                       cursor: 'pointer'}}
                  />
                </div>

                <div className={'col-lg-8'}>
                  <nav className={"navbar navbar-default"} style={{margin:'0px auto', width:'100%', float:'none', marginTop:19}}>
                    <div className={"navbar-header"}>
                      <button type="button" className={"navbar-toggle collapsed"} data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="true">
                        <span className={"sr-only"}>Toggle navigation</span>
                        <span className={"icon-bar"}></span>
                        <span className={"icon-bar"}></span>
                        <span className={"icon-bar"}></span>
                      </button>
                      <MobileView>
                        <a style={{color:'white', height:40, marginTop:'21px', padding:10}} className={'btn btn-success'} href="http://panel.tohumnerede.com.tr" target={'_blank'}>TODAB ÜYE GİRİŞİ</a>
                      </MobileView>
                    </div>
                    <div className={"collapse navbar-collapse"} id="navbar-collapse-1">
                      <ul className={"nav navbar-nav"} style={headerStyles.headerUlStyle}>

                        <li className={this.checkActiveClassHeader("/", false)}><a href="/">ANASAYFA</a>
                        </li>
                        <li className={this.checkActiveClassHeader("/hakkimizda")} >
                          <a  href="/hakkimizda" >HAKKIMIZDA</a></li>
                        <li className={this.checkActiveClassHeader("/haberler")} ><a href="/haberler">SEKTÖREL HABERLER</a></li>
                        <li className={this.checkActiveClassHeader("/duyurular")} ><a href="/duyurular">DUYURULAR</a></li>
                        <li><a href="/contact-us" target={'_blank'}>İLETİŞİM</a></li>
                        {
                          isBrowser ?
                            <li><a style={{color:'white', height:40, marginTop:'-11px', padding:10}} className={'btn btn-success'} href="http://panel.tohumnerede.com.tr" target={'_blank'}>TODAB ÜYE GİRİŞİ</a></li>
                            : <></>
                        }
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>


              <div className={'row hidden-weather-div'} style={{marginTop:20}}>
                <div className={'col-lg-12 hidden-sm hidden-md'} style={{height:48,backgroundColor:'rgb(89, 214, 109)', display:'flex', alignItems:'center'}}>
                  <div className={'col-lg-3'}>
                    <h3 style={{color:'white', marginTop:9, fontSize:17}}>Hava Durumu: <img style={{width:42}} src={weather}/> Ankara {this.state.weather}<sup>℃</sup></h3>
                  </div>

                  <div className={'col-lg-6'}>
                    <marquee scrollamount={'10'} style={{fontSize:'20px', color:'white'}}>{this.state.marquee_text.map((item,i) => <span style={{marginRight:'200px'}} key={i}>{item}</span>)}</marquee>
                  </div>

                  <div className={'col-lg-3'}>
                    <a href="https://twitter.com/tohumnerede" target={'_blang'}>
                      <i className={'fa fa-twitter fa-2x'} style={{backgroundColor:'#55acee', marginRight:30, padding:'5px 11px', borderRadius:'4px', color:'white', float:'right'}}></i>
                    </a>
                    <a href={"https://www.instagram.com/tohumnerede/?hl=tr"} target='_blank'>
                      <i className={'fa fa-instagram fa-2x'} style={{backgroundColor:'#3f729b', marginRight:30, padding:'5px 11px', borderRadius:'4px', color:'white', float:'right'}}></i>
                    </a>

                    <a href={'https://www.facebook.com/tohumnerede/'} target={'_blank'}>
                      <i className={'fa fa-facebook fa-2x'} style={{backgroundColor:'#3b5998', marginRight:30, padding:'5px 12px', borderRadius:'4px', color:'white', float:'right', height:40}}></i>
                    </a>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </header>
    )
  }

}


export default Header;
