/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import {fetchContactData, sendContactData} from './../../redux/actions/mainActions';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const formStyles = {
    inputStyle : {
        padding : '20px 10px',
        width:'100%'
    },
    textareaStyle: {
        padding: '20px 10px',
        width:'100%',
        minHeight:'300px'
    }
};

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                gonderen_adsoyad: '',
                gonderen_telefon: '',
                gonderen_mail: '',
                mesaj: ''
            },
            formError:false
        };

        this.formChangeHandler = this.formChangeHandler.bind(this);
        this.sendContactDataHandler = this.sendContactDataHandler.bind(this);
    }

    componentDidMount () {
        this.props.getContactData(this.props.route.match.params.id)
    }


    checkForm = () => {
        const values = this.state.formData;
        if (values.gonderen_adsoyad == '' || values.gonderen_adsoyad.length < 1 || values.gonderen_telefon == ''
            || values.gonderen_telefon.length < 1 || values.gonderen_mail == '' || values.gonderen_mail.length < 1
            || values.mesaj == '' || values.mesaj.length < 1)  {
                return new Promise((resolve) => {
                    this.setState({formError: true}, resolve);
                  });
        } else {
            return new Promise((resolve) => {
                this.setState({formError: false}, resolve);
              });
        }
    }

    sendContactDataHandler = async(event) => {
        event.persist();
        event.preventDefault();


        if (this.props.postContact.isFetching) {
            return false;
        }

        await this.checkForm();
        let captchaResponse = window.document.querySelector('[name="g-recaptcha-response"]');


        if (this.state.formError === false && captchaResponse.value !== '') {
            this.props.postContactData(this.props.route.match.params.id, this.state.formData, captchaResponse.value);
            return false;
        } else {
            let formValidationErrMessage = (captchaResponse.value === '') ? 'Lütfen Güvenlik Doğrulamasını (Captcha) Boş Bırakmayınız !' : 'Lütfen İletişim Formundaki Tüm Alanları Doldurunuz.';
            MySwal.fire({
                title: <p>{formValidationErrMessage}</p>,
                type: 'error',
                footer: 'Tohum Dağıtıcıları Alt Birliği',
            });
        }

        return false;
    }


    formChangeHandler = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevState => { let formData = prevState.formData; formData[name] = value; return {formData} });
    }


    componentWillUpdate(nextProps, nextUpdate) {
        console.log(this.props.postContact, nextProps.postContact);
           if (this.props.postContact.status !== nextProps.postContact.status && nextProps.postContact.status) {
               MySwal.fire({
                   title: <p>Tebrikler, İletişim İsteğiniz Başarı İle Gönderildi.</p>,
                   footer: 'Tohum Dağıtıcıları Alt Birliği',
                   type: 'success',
                   onOpen: () => {
                   }
               }).then(() => {
                   location.href = '/';
               })
           } else if (this.props.postContact.status !== nextProps.postContact.status && nextProps.postContact.status === false) {
               MySwal.fire({
                   title: <p>Uyarı, İletişim İsteğiniz Kaydedilirken Bir Problemle Karşılaşıldı</p>,
                   footer: 'Tohum Dağıtıcıları Alt Birliği',
                   type: 'error',
                   onOpen: () => {
                   }
               });
           }

         /*  */

    }

    render() {
        return (
            <div className={'environment-main-content'} style={{paddingTop:'10px'}}>

                <div className={'environment-main-section'}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <div className={'environment-title-wrap'}>
                                    <div className={'environment-fancy-title'}>
                                        <h2> İletişime <span> Geçin </span></h2>
                                    </div>
                                    <p>Tohum Satıcısı Bayiimiz ile İletişime Geçebilirsiniz.</p>
                                </div>
                            </div>

                            <div className={'col-md-3'}>
                                <div className={'environment-contactus-info'}>
                                    <ul className={'row'}>
                                        <li className={'col-md-12'}>
                                            <span><i className={'fa fa-user'}></i></span>
                                            <div className={'environment-contactus-text'}>
                                                <h5>Bayi Adı:</h5>
                                                <p> {this.props.contactData.bayi || '-'} </p>
                                            </div>
                                        </li>
                                        <li className={'col-md-12'}>
                                            <span><i className={'fa fa-phone'}></i></span>
                                            <div className={'environment-contactus-text'}>
                                                <h5>Bayi Telefon:</h5>
                                                <p> {this.props.contactData.tel || 'Belirtilmemiş'} </p>
                                            </div>
                                        </li>

                                        <li className={'col-md-12'}>
                                            <span><i className={'fa fa-map-marker'}></i></span>
                                            <div className={'environment-contactus-text'}>
                                                <h5>Bayi Adresi:</h5>
                                                <p> {this.props.contactData.bayi_adres || '-'} </p>
                                                <p> {this.props.contactData.il || '-'} </p>

                                            </div>
                                        </li>

                                        <li className={'col-md-12'}>
                                            <span><i className={'fa fa-leaf'}></i></span>
                                            <div className={'environment-contactus-text'}>
                                                <h5>Tür - Çeşit:</h5>
                                                <p> {this.props.contactData.name || '-'} </p>
                                            </div>
                                        </li>

                                        <li className={'col-md-12'}>
                                            <span><i className={'fa fa-shopping-cart'}></i></span>
                                            <div className={'environment-contactus-text'}>
                                                <h5>Stok:</h5>
                                                <p> {this.props.contactData.stok || 'Belirtilmemiş'} </p>
                                            </div>
                                        </li>

                                        <li className={'col-md-12'}>
                                            <span><i className={'fa fa-try'}></i></span>
                                            <div className={'environment-contactus-text'}>
                                                <h5>Birim Fiyatı:</h5>
                                                <p> {this.props.contactData.fiyat || 'Belirtilmemiş'} </p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className={'col-md-9'}>
                                <div className={'environment-contact-form'}>
                                    <form  className={'myform'} onSubmit={this.sendContactDataHandler}>
                                        <div className={'form-group'}>
                                            <label>Ad - Soyad *</label>
                                            <input type='text'  onChange={this.formChangeHandler} style={{width:'100%'}} style={formStyles.inputStyle} name='gonderen_adsoyad' placeholder='Ad - Soyad' />
                                        </div>

                                        <div className={'form-group'}>
                                            <label>Email Adresiniz*</label>
                                            <input type='text' onChange={this.formChangeHandler} style={{width:'100%'}} style={formStyles.inputStyle} name='gonderen_mail' placeholder='Email Adresiniz' />
                                        </div>


                                        <div className={'form-group'}>
                                            <label>Telefon Numaranız *</label>
                                            <input type='text' onChange={this.formChangeHandler} style={{width:'100%'}} style={formStyles.inputStyle} name='gonderen_telefon' placeholder='Telefon Numaranız' />
                                        </div>

                                        <div className={'form-group'}>
                                            <label>Mesajınız *</label>
                                            <textarea name='mesaj' onChange={this.formChangeHandler} style={formStyles.textareaStyle} ></textarea>
                                        </div>

                                        <div className={"form-group"}>
                                            <div className="g-recaptcha" data-sitekey="6Lf9SIUfAAAAAABGxV-QuMFV6GrZQwEIbx4_SWJ-"></div>
                                        </div>

                                        <div className={'form-group'}>
                                            <button type='submit' className={'btn btn-success'}><i className={'fa fa-envelope'}></i> Gönder</button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        contactData: state.contactAPI.contactData,
        notFound: state.contactAPI.notFound,
        error: state.contactAPI.error,
        isFetching: state.contactAPI.isFetching,
        postContact: {
            isFetching: state.contactSendAPI.isFetching,
            sendError: state.contactSendAPI.sendError,
            status: state.contactSendAPI.status,
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getContactData: (stockId) => {
            dispatch(fetchContactData(stockId))
        },

        postContactData: (stockId, formData, captchaResponse) => {
            dispatch(sendContactData(stockId, formData, captchaResponse));
        }
    }
}

Contact = connect(mapStateToProps, mapDispatchToProps)(Contact);


export default Contact;
