// eslint-disable
import React, { Component } from 'react';
import {connect} from 'react-redux';
import { fetchPosts, fetchSearchUtilities, setSearchKeyword } from '../../redux/actions/mainActions'
import AsyncSelect from 'react-select/async/dist/react-select.esm'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;


class SearchResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            word: '',
            searchResults: []
        }
    }

    componentWillMount() {
      this.timer = null;
    }

    componentWillUpdate(nextProps, nextState) {
        if( this.props.results.data !== nextProps.results.data) {
        if (typeof nextProps.results.data !== 'undefined'
                && typeof nextProps.results.data.items !== 'undefined') {
                    const cloned = [...nextProps.results.data.items];
                    this.setState({searchResults: cloned});

                }

            }
    }

    paginationAlgorithm(c, m) {
        var current = c,
            last = m,
            delta = 2,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }


    getTotalRecords() {
       if (typeof this.props.results.data !== 'undefined' && typeof this.props.results.data.items !== 'undefined') {
           return this.props.results.data.cursor.recordsFiltered;
       }

       return 0;
    }

    searchInputHandler = event => {
      clearTimeout(this.timer);
      const {name, value} = event.target;
      this.setState({word: value}, () => {
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
        this.search(value);
      });


    }

    triggerChange = () => {
      try {
        const word = this.state.word;
        this.props.onChange(word);
      } catch (e) {
        console.log('e', e);
        console.log('this.state', this.state);
      }

    }



  searchInTable() {
        let word = this.state.word.toLocaleLowerCase('TR');

        let cloneResult = [];

       if (typeof this.props.results.data !== 'undefined' && typeof this.props.results.data.items !== 'undefined') {
            cloneResult =  [...this.props.results.data.items || []];
       }

       const originalResults = [...cloneResult];

        const filtered = originalResults.filter(result => {
            const condition = Object.keys(result).filter(subItemKey => {
                let itemValue = ((result[subItemKey] || '').toString()).toLocaleLowerCase('TR');

                return itemValue.indexOf(word) !== -1;
            });

            return condition.length > 0;
        });

        this.setState({searchResults: filtered});

    }

    setActivePage(page){
        this.props.changePage(page, this.state.word);
    }

    paginationServiceRenderer() {

        if ( typeof this.props.results.data !== 'undefined' && typeof this.props.results.data.cursor !== 'undefined') {
            const {currentPage, totalPage} = this.props.results.data.cursor;

            let pages = this.paginationAlgorithm(currentPage, totalPage);

            let paginationItems = [];
            for(var i = 0; i < pages.length; i++) {
                let index = pages[i];
                if (pages[i] !== '...') {
                    paginationItems.push(<li className={(index === currentPage) ? 'active' : ''}><a style={{cursor:'pointer'}} onClick={() => {this.setActivePage(index)}} >{index}</a></li>);
                } else {
                    paginationItems.push(<li><a style={{cursor:'pointer'}}>...</a></li>);
                }

            }

            return (
                <ul className={'pagination'}>
                    {paginationItems}
                </ul>
            );
        } else {
            return (<></>);
        }

    }


  search(value) {
    this.props.onSearch({kelime: this.props.searchUtilies.searchKeyword, ek_kelime: this.state.word});
  };




  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange();
    }
  }



  generateResultsTable() {

        let resultRows = [];
        // this.props.results.data !== 'undefined' && typeof this.props.results.data.items !== 'undefined'
        if (this.state.searchResults.length > 0) {

            this.state.searchResults.map(result => {
                resultRows.push(
                    <tr>
                        <td> {result.bayi} </td>
                        <td> {result.tur} </td>
                        <td> {result.miktar} </td>
                        <td> {result.fiyat} </td>
                        <td> {result.il} </td>
                        <td> {result.ilce} </td>
                        <td> {result.adres} </td>
                        <td style={{verticalAlign: 'middle'}}>
                            <a className={'btn btn-warning'} onClick={() => {
                              window.gtag_report_conversion({
                                  type: 'search',
                                  forwarder: 'contact_in_results',
                                  resultId: result.id,
                                  resultData: result || {}
                                }
                              );
                            }} target="_blank" href={`/iletisim/${result.id}`}>İletişime Geç </a>
                        </td>
                    </tr>
                )
            });
        } else {
            resultRows.push(<tr><td colSpan={8}> Gösterilecek Kayıt Bulunamadı </td> </tr>);
        }

        return (
            <div >

                <div className={'form-group'}>
                    <label>Tabloda Ara:</label>
                    <input type='text' onKeyDown={(event) => this.handleKeyDown(event)} onChange={(event) => this.searchInputHandler(event)} value={this.state.word} className={'form-control'} style={{padding:'17px 15px', fontSize:16}} placeholder='arayın...' />
                </div>

              <MobileView>
                <div className="row">
                  <div className="col-xs-1">
                    <i className="fa fa-arrow-left"></i>
                  </div>
                  <div className="col-xs-9" style={{paddingRight: '0px', paddingLeft: '13px'}}>
                    Sağa - Sola Kaydırabilirsiniz
                  </div>
                  <div className="col-xs-1">
                    <i className="fa fa-arrow-right"></i>
                  </div>
                </div>
              </MobileView>

              <div className={'table-responsive'}>
                <table className={'table table-bordered table-hover table-striped'}>
                    <thead>
                        <tr>
                            <th>Bayi Ünvanı</th>
                            <th>Tür Adı</th>
                            <th>Miktar</th>
                            <th>Birim Fiyat</th>
                            <th>İl</th>
                            <th>İlçe</th>
                            <th>Adres</th>
                            <th>İletişim</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resultRows}
                    </tbody>
                </table>
              </div>

                <div className={'row'}>
                    <div className={'col-lg-6'}>
                        {this.paginationServiceRenderer()}
                    </div>

                    <div className={'col-lg-6'} style={{textAlign:'right'}}>
                        <b>Toplam Kayıt: {this.getTotalRecords()}</b>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <div style={{marginTop:'-88px'}} className={'tableMobile'} id={"resultsTable"}>
                {this.generateResultsTable()}
            </div>
        );
    }

}


const mapStateToProps = state => {
    return {
        results: state.postsByAPI.results,
        didInvalidate: state.postsByAPI.didInvalidate,
        isFetching: state.postsByAPI.isFetching,
        searchUtilies: state.utilitiesAPI
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSearch: (params) => {
      dispatch(fetchPosts(params))
    },
    setSearchKeyword: (keyword) => {
      dispatch(setSearchKeyword(keyword));
    }

  }
}


SearchResults = connect(mapStateToProps, mapDispatchToProps)(SearchResults);

export default SearchResults;


