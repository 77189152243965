import React, {Component} from 'react';


const AboutUs = () => {
  return (
    <div className={'container'} style={{marginBottom:150}}>
      <div className={'row'}>
        <div className={'col-lg-12'}>
          <h1 align='center'>Hakkımızda</h1>
          <br/>
          <div style={{fontSize:18, color:'black', textAlign:'center', paddingLeft:30, paddingRight:30}}>
            <p>  TOHUM NEREDE? PLATFORMU NEDİR?</p>

            <p>Tohum Nerede Platformu, 5553 Sayılı Tohumculuk Kanununa göre faaliyet gösteren Tohum Dağıtıcıları Alt Birliği tarafından kurulmuştur.</p>

            <p>Bu platformda sadece TODAB üyesi bayilerimizin satışa sundukları tohumluklar yer almaktadır.</p>

            <p>Aradığı tohumluğu kolayca bulmak isteyen çiftçilerimiz ve halkımız, kullanımı çok basit olan bu site ile aradığı tohumluğa kolayca ve farklı seçenekleri ile birlikte ulaşabileceklerdir. İstediği bayi ile iletişime geçerek, ihtiyaçlarını daha kolay ve zahmetsizce giderme imkânına sahip olacaklardır.</p>

            <p>Tüm çiftçilerimiz ve vatandaşlarımızın doğrudan kullanımına sunulan bu platform her geçen gün büyümeye ve gelişmeye devam edecektir.</p>
          </div>
      </div>
    </div>
    </div>
  )
}

export default AboutUs;