    import { combineReducers } from 'redux'
    import {
  START_SEARCH, END_SEARCH, INVALID_SEARCH, START_GET_UTILITIES, END_GET_UTILITIES, ERROR_GET_UTILITIES, ERROR_SEARCH,
  START_GET_CONTACT_DATA, END_GET_CONTACT_DATA, ERROR_GET_CONTACT_DATA,
  START_SEND_CONTACT_DATA, END_SEND_CONTACT_DATA, ERROR_SEND_CONTACT_DATA, SET_SEARCH_KEYWORD
} from './../actions/mainActions'

    const initialState = {
        results: [],
        queries: []
    }
  
    function mainReducer(state = initialState, action) {

        switch (action.type) {
            case START_SEARCH: 
                return Object.assign({}, state, {
                    searchResults: null 
                });

            case START_GET_UTILITIES: 
            return Object.assign({}, state, {
                utilitiesResult: null 
            });
        }

        return state
    }



    const searchState = {
        isFetching: false,
        didInvalidate: false,
        error: false,
        results: []
    };

    function searchResults( state = searchState, action) {
        switch (action.type) {
            case INVALID_SEARCH: 
                return Object.assign({}, state, {
                    didInvalidate: true
                });

            case START_SEARCH: 
                return Object.assign({}, state, {
                    isFetching: true,
                    didInvalidate: false,
                    error: true
                });

            case END_SEARCH: 
                return Object.assign({}, state, {
                    isFetching: false,
                    didInvalidate: false,
                    results: action.results,
                    error: false
                });

            case ERROR_SEARCH: 
                return Object.assign({}, state, {
                    error: true
                });
            default:
                return state
        }
    }


    
    function postsByAPI(state = searchState, action) {
        switch (action.type) {
            case INVALID_SEARCH:
            case END_SEARCH:
            case START_SEARCH:
            case ERROR_SEARCH:
                return Object.assign({}, state, searchResults(state, action))
            default:
            return state
        }
    }


    const utilitiesState = {
        isFetching: false,
        categories: [],
        types: [],
        kinds: [],
        error: false,
        searchKeyword: ''
    };

    function utilitiesAPI(state = utilitiesState, action) {
        switch (action.type) {
            case END_GET_UTILITIES:
            case START_GET_UTILITIES:
            case ERROR_GET_UTILITIES: 
                let k = utilitiesResult(state, action);
                return Object.assign({}, state, k);

          case SET_SEARCH_KEYWORD:
              return Object.assign({}, state, {searchKeyword: action.payload.keyword});

            default:
                return state
        }
    }

    function utilitiesResult( state = utilitiesState, action) {
        switch (action.type) {
            case START_GET_UTILITIES: 
                return Object.assign({}, state, {
                    isFetching: true,
                    error: false,
                });

            case END_GET_UTILITIES: 
                return Object.assign({}, state, {
                    isFetching: false,
                    error: false,
                    [action.processType]: action[action.processType]
                });

            case ERROR_GET_UTILITIES: 
                return Object.assign({}, state, {
                    isFetching: false,
                    error: true,
                });
            default:
                return state
        }
    }




    /**
     * Contact Modulu icin 
     */

    const contactState = {
        isFetching: false,
        contactData: {},
        notFound: false,
        error: false
    };

    function contactAPI(state = contactState, action) {
        switch (action.type) {
            case START_GET_CONTACT_DATA:
            case END_GET_CONTACT_DATA:
            case ERROR_GET_CONTACT_DATA:
                return Object.assign({}, state, contactResults(state, action));
            default:
                return state
        }
    }

    function contactResults( state = contactState, action) {
        switch (action.type) {
            case START_GET_CONTACT_DATA: 
                return Object.assign({}, state, {
                    isFetching: true,
                    error: false,
                    notFound : false
                });

            case END_GET_CONTACT_DATA: 
                return Object.assign({}, state, {
                    isFetching: false,
                    error: false,
                    contactData: action.contactData,
                    notFound: typeof action.notFound !== 'undefined' ? true : false
                });

            case ERROR_GET_CONTACT_DATA: 
                return Object.assign({}, state, {
                    isFetching: false,
                    error: true,
                });
            default:
                return state
        }
    };


    const sendContactState = {
        isFetching: false,
        status: null,
        sendError: false
    };


    function contactSendAPI (state = sendContactState, action) {
        switch (action.type) {
            case START_SEND_CONTACT_DATA:
            case END_SEND_CONTACT_DATA:
            case ERROR_SEND_CONTACT_DATA:
                return Object.assign({}, state, contactSend(state, action));
            default:
                return state
        }
    }

    function contactSend(state = sendContactState, action) {
        switch (action.type) {
            case START_SEND_CONTACT_DATA: 
                return Object.assign({}, state, {
                    isFetching: true,
                    sendError: false,
                });

            case END_SEND_CONTACT_DATA: 
                return Object.assign({}, state, {
                    isFetching: false,
                    sendError: false,
                    status: action.status,
                });

            case ERROR_SEND_CONTACT_DATA: 
                return Object.assign({}, state, {
                    isFetching: false,
                    sendError: true,
                });
            default:
                return state
        }
    }

    const rootReducer = combineReducers({
        postsByAPI,
        utilitiesAPI,
        contactAPI,
        contactSendAPI
    });

    export default rootReducer;