import React, { Component } from 'react';

import logo  from "./../../assets/images/logo.png";

class Footer extends Component {

    constructor(props) {
        super(props);
    } 


    render() {
        return (
            <footer id="environment-footer" className={"environment-footer-one"}>

                <div className={"environment-footer-widget"}  style={{padding:'30px 50px'}}>
                    <div className={"container"}>
                        <div className={"row"}>

                            <aside className={"col-md-3 widget widget_contact_info"}>
                                <div className={"environment-footer-title"}><h2>Tohum Dağıtıcıları Alt Birliği</h2></div>

                                <ul>
                                    <li><i className={"fa fa-envelope-o"}></i> <a href="mailto:info@tohumnerede.com.tr">info@tohumnerede.com.tr</a></li>
                                    <li><i className={"fa fa-map-marker"}></i> Ehlibeyt Mah. Tekstilciler Cad. 1271 Sokak (6 Sok.) No: 21
                                      Kat: 3  9/10 Nolu Daire Libra Kule Çankaya/ANKARA TÜRKİYE</li>
                                    <li><i className={"fa fa-phone"}></i>  +90(312) 418 1695-96 </li>
                                </ul>
                                <ul className={"environment-footer-social"}>
                                    <li><a target="_blank" href="https://www.facebook.com/tohumnerede/" className={"icon-facebook2"}></a></li>
                                    <li><a target="_blank" href="https://twitter.com/tohumnerede" className={"icon-social43"}></a></li>
                                    <li><a target="_blank" href="https://www.instagram.com/tohumnerede/?hl=tr" className={"fa fa-instagram"}></a></li>
                                </ul>
                            </aside>
                        </div>
                    </div>
                </div>

                <div className={"environment-copyright"} >
                    <div className={"container"} style={{padding:'10px 50px'}}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <a href="/" className={"environment-footer-logo"}>
                                <img src={logo}  style={{height:50, verticalAlign: 'middle'}} alt="" />
                                <span style={{color:'white'}}>Tohum Dağıtıcıları Alt Birliği Hizmetidir.</span>
                                </a>
                                <p style={{color:'white'}}>© 2019, Her Hakkı Saklıdır -  <a href="https://todab.org.tr" style={{color:'white'}}>TODAB</a></p>
                                <a href="#" className={"environment-back-top"}><i className={"fa fa-angle-up"}></i></a>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        );
    }

}

export default Footer;