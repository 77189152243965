import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { fetchContactData, sendContactData } from '../../redux/actions/mainActions'
import { connect } from 'react-redux'
const MySwal = withReactContent(Swal)

const formStyles = {
  inputStyle : {
    padding : '20px 10px',
    width:'100%'
  },
  textareaStyle: {
    padding: '20px 10px',
    width:'100%',
    minHeight:'300px'
  }
};


const MapMarker = ({ text }) => (
    <i style={{color:'red', fontSize:35}} className={"fa fa-map-marker"}></i>
);



class ContactUs extends Component {

  constructor (props) {
    super(props);
    this.state = {
      formData: {
        fullname: '',
        subject: '',
        email: '',
        message: ''
      },
      formError:false,
      center: {
        lat: 39.89192983000056,
        lng: 32.81530654569701
      },
      zoom: 15
    }

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.sendContactDataHandler = this.sendContactDataHandler.bind(this);
  }



  checkForm = () => {
    const values = this.state.formData;
    if (values.fullname == '' || values.fullname.length < 1 || values.subject == ''
      || values.subject.length < 1 || values.email == '' || values.email.length < 1
      || values.message == '' || values.message.length < 1 || !/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(values.email))  {
      return new Promise((resolve) => {
        this.setState({formError: true}, resolve);
      });
    } else {
      return new Promise((resolve) => {
        this.setState({formError: false}, resolve);
      });
    }
  }


  sendContactDataHandler = async(event) => {
    event.persist();
    event.preventDefault();

    if (this.props.postContact.isFetching) {
      return false;
    }

    await this.checkForm();
    let captchaResponse = window.document.querySelector('[name="g-recaptcha-response"]');

    if (this.state.formError === false && captchaResponse.value !== '') {
      this.props.postContactData(this.props.route.match.params.id, this.state.formData, captchaResponse.value);
      return false;
    } else {
      let formValidationErrMessage = (captchaResponse.value === '') ? 'Lütfen Güvenlik Doğrulamasını (Captcha) Boş Bırakmayınız !' : 'Lütfen İletişim Formundaki Tüm Alanları Doldurunuz.';
      MySwal.fire({
        title: <p>{formValidationErrMessage}</p>,
        type: 'error',
        footer: 'Tohum Dağıtıcıları Alt Birliği',
      });
    }

    return false;
  }


  formChangeHandler = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => { let formData = prevState.formData; formData[name] = value; return {formData} });
  }


  componentWillUpdate(nextProps, nextUpdate) {
    if (this.props.postContact.status !== nextProps.postContact.status && nextProps.postContact.status) {
      MySwal.fire({
        title: <p>Tebrikler, İletişim İsteğiniz Başarı İle Gönderildi.</p>,
        footer: 'Tohum Dağıtıcıları Alt Birliği',
        type: 'success',
        onOpen: () => {
        }
      }).then(() => {
        window.location.href = '/';
      })
    } else if (this.props.postContact.status !== nextProps.postContact.status && nextProps.postContact.status === false) {
      MySwal.fire({
        title: <p>Uyarı, İletişim İsteğiniz Kaydedilirken Bir Problemle Karşılaşıldı</p>,
        footer: 'Tohum Dağıtıcıları Alt Birliği',
        type: 'error',
        onOpen: () => {
        }
      });
    }
  }

  render () {
    return (
      <div className={'environment-main-content'} style={{paddingTop:'10px'}}>

        <div className={'environment-main-section'}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-md-12'}>
                <div className={'environment-title-wrap'}>
                  <div className={'environment-fancy-title'}>
                    <h2> İletişime <span> Geçin </span></h2>
                  </div>
                  <p>Tohum Satıcısı Bayiimiz ile İletişime Geçebilirsiniz.</p>
                </div>
              </div>

              <div className={'col-md-3'}>
                <div className={'environment-contactus-info'}>
                  <ul className={'row'}>
                    <li className={'col-md-12'}>
                      <span><i className={'fa fa-user'}></i></span>
                      <div className={'environment-contactus-text'}>
                        <h5>Tohum Dağıtıcıları Alt Birliği</h5>
                      </div>
                    </li>
                    <li className={'col-md-12'}>
                      <span><i className={'fa fa-phone'}></i></span>
                      <div className={'environment-contactus-text'}>
                        <h5>Telefon:</h5>
                        <p>0 312 418 1695-96 </p>
                        <p>0 546 852 5852</p>
                      </div>
                    </li>

                    <li className={'col-md-12'}>
                      <span><i className={'fa fa-phone'}></i></span>
                      <div className={'environment-contactus-text'}>
                        <h5>Fax:</h5>
                        <p>0 312 418 1693-97</p>
                      </div>
                    </li>

                    <li className={'col-md-12'}>
                      <span><i className={'fa fa-map-marker'}></i></span>
                      <div className={'environment-contactus-text'}>
                        <h5>Adres:</h5>
                        <p>Ehlibeyt Mahallesi Tekstilciler Caddesi 1271 Sokak (6. Sokak) No: 21 Kat: 3 Daire: 9/10 Libra Kule Çankaya/ANKARA</p>

                      </div>
                    </li>

                    <li className={'col-md-12'}>
                      <span><i className={'fa fa-envelope'}></i></span>
                      <div className={'environment-contactus-text'}>
                        <h5>E-Posta:</h5>
                        <p> info@tohumnerede.com.tr </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={'col-md-9'}>

                <div className={'row'}>
                  <div className={'col-lg-12'}>
                    <div style={{ height: '350px', width: '100%' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{key: 'AIzaSyAUQjOqhQx1x9_0dH7Szfqsk7VJtGVBXwM'}}
                      defaultCenter={this.state.center}
                      defaultZoom={this.state.zoom}
                    >
                      <MapMarker lat={this.state.center.lat}
                                 lng={this.state.center.lng}/>
                    </GoogleMapReact>
                    </div>
                  </div>
                </div>

                <div className={'environment-contact-form'}>
                  <form  className={'myform'} onSubmit={this.sendContactDataHandler}>
                    <div className={'form-group'}>
                      <label>Ad - Soyad *</label>
                      <input type='text'  onChange={this.formChangeHandler} style={{width:'100%'}} style={formStyles.inputStyle} name='fullname' placeholder='Ad - Soyad' />
                    </div>

                    <div className={'form-group'}>
                      <label>Email Adresiniz*</label>
                      <input type='text' onChange={this.formChangeHandler} style={{width:'100%'}} style={formStyles.inputStyle} name='email' placeholder='Email Adresiniz' />
                    </div>


                    <div className={'form-group'}>
                      <label>Konu *</label>
                      <input type='text' onChange={this.formChangeHandler} style={{width:'100%'}} style={formStyles.inputStyle} name='subject' placeholder='Telefon Numaranız' />
                    </div>

                    <div className={'form-group'}>
                      <label>Mesajınız *</label>
                      <textarea name='message' onChange={this.formChangeHandler} style={formStyles.textareaStyle} ></textarea>
                    </div>


                    <div className={"form-group"}>
                      <div className="g-recaptcha" data-sitekey="6Lf9SIUfAAAAAABGxV-QuMFV6GrZQwEIbx4_SWJ-"></div>
                    </div>

                    <div className={'form-group'}>
                      <button type='submit' className={'btn btn-success'}><i className={'fa fa-envelope'}></i> Gönder</button>
                    </div>

                  </form>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>)
  }
}

const mapStateToProps = state => {
  return {
    contactData: state.contactAPI.contactData,
    notFound: state.contactAPI.notFound,
    error: state.contactAPI.error,
    isFetching: state.contactAPI.isFetching,
    postContact: {
      isFetching: state.contactSendAPI.isFetching,
      sendError: state.contactSendAPI.sendError,
      status: state.contactSendAPI.status,
    }
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {

    postContactData: (stockId, formData, captchaResponse) => {
      dispatch(sendContactData(null, formData, captchaResponse));
    }
  }
}


ContactUs = connect(mapStateToProps, mapDispatchToProps)(ContactUs);
export default ContactUs;
