import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';

import PublicLayout  from './PublicLayout/publicLayout';
import publicRoutes  from './../routes/publicRoutes';

import './../assets/css/bootstrap.css';
import './../assets/css/font-awesome.css';
import './../assets/css/flaticon.css';
import './../assets/css/slick-slider.css';
import './../assets/css/fancybox.css';
import './../assets/style.css';
import './../assets/css/color.css';
import './../assets/css/responsive.css';


import NotFound from './../components/NotFound/notFound';

class Template extends Component {

    constructor(props) {
        super(props);
     }

    componentWillMount() {
    }

    render() {
        return (
        <BrowserRouter>
            <Switch>

                { _.map(publicRoutes, (route, key) => {
                    const { component, path } = route;
                    return (
                        <Route
                            exact
                            path={path}
                            key={key}
                            render={ (route) => <PublicLayout component={component} route={route} />}
                        />
                    )
                }) }
                <Route component={ NotFound } />
            </Switch>
        </BrowserRouter>
        );
    }
}

export default Template;